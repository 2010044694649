.header-md::after {
  content: none;
}

b {
  font-weight: 500 !important;
}

ion-tab-bar {
  min-height: 72px;
  --border: none;
  /* box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24); */
}
ion-tab-bar.main-header {
  display: flex;
  justify-content: flex-start;
  padding-left: 10rem;
  gap: 41px;
}

ion-tab-button ion-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-primary);
}

ion-tab-button {
  position: relative;
  flex: none;
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
}

ion-tab-button.tab-selected:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 4px;
  background-color: var(--ion-color-secondary);
}

ion-toolbar {
  box-shadow: none;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 48px !important;
  padding-right: 48px !important;
}

ion-content {
  --background: #ffffff;
}

ion-content ion-grid {
  padding-left: 32px;
  padding-right: 32px;
}

ion-col {
  padding: 0;
}

ion-list {
  width: 100%;
  background-color: #ffffff !important;
}

ion-list.list-md {
  padding-top: 0;
  padding-bottom: 0;
}

ion-card {
  --background: #ffffff;
}

ion-card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
}

ion-item {
  --background: transparent;
  overflow: visible;
}

ion-title {
  --color: #000000;
  padding-left: 16px !important;
  padding-right: 16px !important;
  position: relative;
  display: flex !important;
  align-items: center !important;
}

ion-title ion-button {
  transform: translateY(-6px) translateX(8px);
  position: absolute;
}

ion-button {
  text-transform: none;
}
ion-button::part(native) {
  font-size: 16px;
}

ion-toolbar ion-badge.ion-color-light {
  background-color: #ffffff;
}

ion-toolbar .title-container {
  display: flex;
  align-items: center;
  position: relative;
  gap: 2rem;
  flex-grow: 0;
}

ion-toolbar .header-segment {
  /* margin-left: 3rem; */
}

ion-toolbar .header-segment ion-segment-button {
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
  font-weight: 600 !important;
  text-transform: none;
  font-size: 16px;
}

ion-segment-button::part(indicator-background) {
  background: var(--ion-color-secondary);
}
.segment-blue ion-segment-button::part(indicator-background) {
  /* background: var(--ion-color-secondary); */
  background: var(--color-checked);
}

ion-segment.segment-blue ion-segment-button {
  --padding-start: 0.25rem;
  --padding-end: 0.25rem;
  --indicator-height: 4px;
  font-weight: 500;
  text-transform: none;
  font-size: 17px;
  color: var(--color-checked);
  padding-inline-start: var(--padding-start);
  padding-inline-end: var(--padding-end);
  --background-hover: none;
  letter-spacing: unset;
}
ion-segment.segment-blue ion-segment-button:hover ion-label {
  background: none;
  color: var(--color-checked);
}

.segment-light-blue ion-segment-button::part(indicator-background) {
  /* background: var(--ion-color-secondary); */
  background: var(--ion-color-secondary);
}
ion-segment.segment-light-blue ion-segment-button {
  --padding-start: 0rem;
  --padding-end: 0rem;
  --indicator-height: 3px;
  font-weight: 500;
  text-transform: none;
  font-size: 17px;
  color: var(--ion-color-secondary);
  padding-inline-start: var(--padding-start);
  padding-inline-end: var(--padding-end);
  --background-hover: none;
}
ion-segment.segment-light-blue ion-segment-button:hover ion-label {
  background: none;
  color: var(--ion-color-secondary);
}

ion-segment-button::part(indicator) {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 4px;
  background-color: var(--ion-color-secondary);
}

ion-toolbar .title-container h1,
ion-toolbar .title-container h2,
ion-toolbar .title-container h3 {
  font-size: 16px;
  margin-bottom: 0 !important;
  margin-right: 8px;
}
ion-toolbar .title-container ion-badge {
  color: var(--ion-text-color);
}

ion-badge {
  transform: translateY(3px);
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.container-narrow {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}
.container-wide {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.container-narrow.attachment,
.container-wide.attachment {
  margin-top: 32px;
}

.popover-content {
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  top: 64px !important;
}

.searchbar-input {
  box-shadow: none !important;
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

.ion-margin-none {
  margin: 0 !important;
}

.ion-margin-top {
  margin-top: 16px !important;
}

.ion-margin-right {
  margin-right: 16px !important;
}

.ion-margin-bottom {
  margin-bottom: 16px !important;
}

.ion-margin-left {
  margin-left: 16px !important;
}

.ion-margin-top-half {
  margin-top: 8px !important;
}

.ion-margin-right-half {
  margin-right: 8px !important;
}

.ion-margin-bottom-half {
  margin-bottom: 8px !important;
}

.ion-margin-left-half {
  margin-left: 8px !important;
}

.ion-padding-none {
  padding: 0 !important;
  -webkit-padding-start: 0 !important;
  -webkit-padding-end: 0 !important;
}

.ion-padding-top {
  padding-top: 16px !important;
}

.ion-padding-right {
  padding-right: 16px !important;
}

.ion-padding-bottom {
  padding-bottom: 16px !important;
}

.ion-padding-left {
  padding-left: 16px !important;
}

.ion-padding-top-half {
  padding-top: 8px !important;
}

.ion-padding-right-half {
  padding-right: 8px !important;
}

.ion-padding-bottom-half {
  padding-bottom: 8px !important;
}

.ion-padding-left-half {
  padding-left: 8px !important;
}

.ion-box-shadow {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16),
    0px 1px 2px rgba(8, 35, 48, 0.24);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0 !important;
  margin-bottom: 16px !important;
}

h1 {
  font-size: 20px !important;
  font-weight: 600 !important;
}
h2 {
  font-size: 18px !important;
  font-weight: 600 !important;
}
h3 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
h4 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

h6 {
  font-size: 14px !important;
}

.text-light {
  color: #6d7079;
  font-weight: 400 !important;
}

.inner-wrap {
  text-decoration: none;
  display: block;
  padding: 16px 0px 16px 0px;
  width: 100%;
}

.ticket-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.marker-inner-wrap {
  position: relative;
}

.marker-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
}

.marker-title p {
  margin-bottom: 0 !important;
}

.marker-inner-wrap p.map {
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  text-shadow: -2px 0 #ffffff, 0 2px #ffffff, 2px 0 #ffffff, 0 -2px #ffffff;
  margin-bottom: 0;
  min-width: 120px;
}

.marker-inner-wrap p.sat {
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  text-shadow: -2px 0 #000000, 0 2px #000000, 2px 0 #000000, 0 -2px #000000;
  margin-bottom: 0;
  min-width: 120px;
}

.marker-inner-wrap p:nth-child(2) {
  font-size: 16px;
}

.marker-inner-wrap a {
  text-decoration: none;
  text-align: center;
  display: block;
}

.marker-status {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}

.marker-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.marker-list-indicator {
  padding: 16px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.marker-list-indicator.primary:before {
  background-color: var(--ion-color-primary) !important;
}

.marker-list-indicator p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0 !important;
  font-weight: bold;
  z-index: 111;
  position: relative;
  line-height: 1;
}

.marker-list-indicator:before {
  content: '';
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  padding-bottom: 100%;
  transform: translateY(-50%) translateX(-50%);
  background-color: var(--ion-color-danger);
  border-radius: 100%;
  z-index: 11;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12) !important;
}

.marker-list-modal .modal-wrapper {
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12) !important;
}

.marker-list-modal .modal-wrapper .ion-page {
  padding: 0 16px 16px 16px;
}

.marker-list-modal .modal-wrapper .ion-page .table-wrap {
  padding-top: 8px;
  max-height: calc(100% - 52px);
  overflow-y: scroll;
}

.ion-border {
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
}

.ion-border-top {
  border-top: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
}

.ion-border-radius {
  border-radius: 4px;
}

.mapboxgl-ctrl {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid #e0e0e0;
}

.mapboxgl-ctrl-group button + button {
  border-top: 1px solid #e0e0e0 !important;
}

.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out {
  width: 30px !important;
  height: 30px !important;
}

.mapboxgl-ctrl-zoom-in span {
  background-image: url('./assets/add-light-mode.svg') !important;
  background-size: 25px 25px;
}

.mapboxgl-ctrl-zoom-out span {
  background-image: url('./assets/remove-light-mode.svg') !important;
  background-size: 25px 25px;
}

.mapboxgl-ctrl-geolocate-active span {
  background-image: url('./assets/locate-light-mode.svg') !important;
  background-size: cover;
  opacity: 1 !important;
}

.mapboxgl-ctrl-geolocate {
  width: 40px !important;
  height: 40px !important;
}

.mapboxgl-ctrl-geolocate span {
  background-image: url('./assets/locate-light-mode.svg') !important;
  background-size: 30px 30px;
  opacity: 0.5;
}

.mapboxgl-ctrl-map-style {
  width: 40px !important;
  height: 40px !important;
  overflow: hidden;
  border-radius: 4px;
}

.mapboxgl-ctrl-map-style p {
  position: absolute;
  top: calc(100% - 16px);
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  color: #ffffff;
  font-size: 10px;
  margin-bottom: 0 !important;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  height: 16px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.mapboxgl-ctrl-map-style .mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-map-style .mapboxgl-control-container {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  position: relative;
}

.map-marker.white path {
  fill: var(--ion-color-white) !important;
}

.map-marker.primary path {
  fill: var(--ion-color-primary) !important;
}

.map-marker.danger path {
  fill: var(--ion-color-danger) !important;
}

.map-marker.warning path {
  fill: var(--ion-color-warning) !important;
}

.map-marker.warning + .marker-status ion-icon {
  color: var(--ion-color-dark) !important;
}

.map-marker.light path {
  fill: var(--ion-color-light) !important;
}

.map-marker.success path {
  fill: var(--ion-color-success) !important;
}

.map-marker + .marker-status ion-icon {
  color: var(--ion-color-light) !important;
}

.map-marker.light + .marker-status ion-icon {
  color: var(--ion-color-dark) !important;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl,
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.icon-wrap {
  background-color: var(--ion-color-danger);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #ffffff;
  text-transform: uppercase;
}

table .ticket-table-handlers ion-button {
  --padding-start: 0px;
  --padding-end: 0px;
}
table .ticket-table-handlers .icon-wrap {
  width: 28px;
  height: 28px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.icon-wrap.primary {
  background-color: var(--ion-color-primary);
}

.icon-wrap.success {
  background-color: var(--ion-color-success);
}

.file-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.file-input-container p {
  flex-shrink: 0;
}

.input-wrap {
  width: 100%;
}

.comment-date,
.contact-title {
  opacity: 0.5;
  font-size: 12px;
}

.ion-avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-avatar {
  background-color: var(--ion-color-primary);
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.contact-avatar h1 {
  color: #ffffff;
}

.MuiPaper-root {
  color: unset !important;
  background-color: transparent !important;
}

.MuiAccordion-root {
  margin: 0 !important;
  margin-top: 0.5rem !important;
  box-shadow: none !important;
  /* border-bottom: 1px solid rgb(0 0 0 / 20%); */
}

.MuiAccordion-root::before {
  content: none !important;
}

.MuiAccordionSummary-root {
  min-height: unset !important;
  padding: 0 !important;
}
.MuiAccordionSummary-root * {
  margin: 0 !important;
}

.MuiAccordionDetails-root {
  display: block !important;
  padding: 0 !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiIconButton-edgeEnd {
  margin-right: 4px !important;
}

.profile {
  /* position: relative; */
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1111;
  /* width: 64px; */
  /* height: 46px; */
  display: flex;
  align-items: center;
}
.profile .status-wrapper {
  display: block;
  padding: 1rem;
  text-wrap: nowrap;
}
.profile .status-wrapper .status-circle {
  width: 25px;
  height: 25px;
  border: 3px solid var(--ion-color-light);
  border-radius: 50%;
  transition: background-color 0.5s ease;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 10px;
}
.profile .inner-wrap {
  /* position: fixed;
	right: 32px;
	top: -8px;
	z-index: 1111;
	width: 64px;
	height: 46px; */
}

.profile div {
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
}

.profile ion-icon {
  color: #000000;
  font-size: 16px;
}

.list-search {
  max-width: 380px;
}

.searchbar-input {
  border: none !important;
  background-color: #ffffff !important;
}

.MuiTableRow-root {
  text-decoration: none;
}

.MuiTableCell-body,
.MuiTableCell-head {
  line-height: 1;
  color: #000000 !important;
  border-bottom: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

.MuiTableRow-root .MuiTableCell-body {
  padding: 0 !important;
}

.MuiTableCell-body a {
  font-size: 16px !important;
  padding: 16px;
  display: block;
  color: unset !important;
  text-decoration: none;
}

.MuiNativeSelect-select {
  color: #000000 !important;
  min-width: 100px !important;
}

.MuiNativeSelect-select::placeholder {
  color: #000000 !important;
}

.MuiInputLabel-root,
.MuiInputLabel-root.Mui-focused {
  color: #000000 !important;
}

.MuiInput-underline:after {
  content: none !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.MuiNativeSelect-icon {
  color: #000000 !important;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filters-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.half-n-half {
  padding: 0 32px 0 32px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

.half-n-half::-webkit-scrollbar {
  display: none;
}

.half-n-half:first-child {
  border-right: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

.half-inner-wrap {
  margin-top: 32px;
  padding-bottom: 32px;
}

.half-fixed-header {
  /* display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 74px;
	position: fixed;
	background-color: #ffffff !important;
	width: calc(50% - 1px);
	transform: translateX(-32px);
	z-index: 1111111;
	border-bottom: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1111111;
  margin-top: 1rem;
}

.half-fixed-header + .half-inner-wrap {
  /* padding-top: 64px; */
  padding-top: 0px;
}

.half-fixed-header ion-segment {
  /* padding-left: 32px;
	padding-right: 32px; */
  justify-content: flex-start;
  gap: 2rem;
}

.half-fixed-header-title {
  padding-left: 32px;
  padding-right: 16px;
}

.half-fixed-header-title h5 {
  margin-bottom: 0 !important;
}

.half-fixed-header-button {
  padding-left: 16px;
  padding-right: 32px;
}

.half-n-half.accessories .half-fixed-header {
  width: calc(33.33333% - 1px);
}

.half-n-half.accessories:last-child {
  border-left: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

.MuiStepper-root {
  padding: 0 !important;
  width: 100%;
}

.MuiStepLabel-root {
  cursor: pointer !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 8px !important;
}

.MuiStepIcon-text {
  font-weight: 600;
}

.MuiStepIcon-active {
  color: var(--ion-color-primary) !important;
}

.MuiStepIcon-completed {
  color: var(--ion-color-success) !important;
}

.stepper-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 4px;
}

.stepper-header .MuiMobileStepper-root {
  width: 100%;
  margin: 0 1rem 1rem 1rem;
  padding: 0;
  background-color: #fafafa !important;
  font-size: 16px;
  font-weight: 500;
}
.stepper-header .MuiMobileStepper-root button {
}
.stepper-header .MuiMobileStepper-root .MuiButton-label,
.stepper-footer .MuiMobileStepper-root .MuiButton-label {
  padding: 0.5rem 1rem;
  font-size: 20px;
}

.stepper-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--ion-color-primary);
}

.badge-date {
  font-weight: 400;
  opacity: 0.8;
  font-size: 16px;
  padding: 2px;
}

.ticket-step-title {
  margin-bottom: 32px !important;
}

.ticket-step-row,
.row {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ticket-step-row.half .ticket-step-input,
.row.half .row-input {
  width: calc(50% - 8px);
}

.ticket-step-row.half .ticket-step-input:first-child,
.row.half .row-input:first-child {
  margin-right: 8px !important;
}

.ticket-step-row.half .ticket-step-input:last-child,
.row.half .row-input:last-child {
  margin-left: 8px !important;
}

.ticket-step-row.half .ticket-step-input,
.row.third .row-input {
  width: calc(33% - 8px);
}

.ticket-step-row.half .ticket-step-input:first-child,
.row.third .row-input:first-child {
  margin-right: 8px !important;
}

.ticket-step-row.half .ticket-step-input:last-child,
.row.third .row-input:last-child {
  margin-left: 8px !important;
}

.ticket-step-row.half .ticket-step-input,
.row.quarter .row-input {
  width: calc(30% - 8px);
}

.ticket-step-row.half .ticket-step-input:first-child,
.row.quarter .row-input {
  margin-right: 8px !important;
}

.ticket-step-row.half .ticket-step-input:last-child,
.row.quarter .row-input:last-child {
  margin-left: 8px !important;
  margin-right: 0px !important;
}

.ticket-step-row .MuiInputBase-formControl,
.row .MuiInputBase-formControl {
  /* margin-top: 0 !important; */
}

.ticket-step-input label,
.row-input label,
p.label {
  display: inline !important;
  font-weight: 500;
  font-size: 16px !important;
  position: relative;
  width: unset !important;
}

.missing-value-alert {
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.missing-value-alert div {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.missing-value-alert ion-icon {
  position: relative;
  pointer-events: none;
}

.ticket-step-input,
.row-input {
  width: 100%;
  position: relative;
  display: flex !important;
}

ion-label .MuiInput-root {
  width: 100%;
}

.file-upload {
  display: flex;
}
ion-label textarea,
ion-label input,
ion-label select,
.login input,
.ticket-step-input select,
.ticket-step-input textarea,
.ticket-step-input input,
.row-input input,
.row-input textarea,
.row-input select,
.file-upload textarea {
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
  border-radius: 4px !important;
  padding: 12px 36px 12px 16px !important;
  font-size: 16px;
  margin-top: 0px;
  outline: none;
  background-color: #fff;
  width: 100% !important;
  -webkit-appearance: none;
}
.MuiNativeSelect-select:focus {
  background-color: transparent !important;
}
.control-room-tickets .MuiNativeSelect-select:focus {
  background-color: #fff !important;
}

ion-label textarea:disabled,
ion-label input:disabled,
ion-label select:disabled,
.login input:disabled,
.ticket-step-input select:disabled,
.ticket-step-input textarea:disabled,
.ticket-step-input input:disabled,
.row-input select:disabled,
.row-input textarea:disabled,
.row-input input:disabled {
  color: #a3a2a2 !important;
}

.link-item:not(.editing) input {
  color: transparent !important;
}

.link-item a {
  position: absolute;
  bottom: 13px;
  left: 17px;
  margin: 0;
}

.link-item ion-button {
  position: absolute;
  bottom: 8px;
  right: 0;
  margin: 0;
}

.link-item.editing a {
  display: none;
}

.link-item.editing ion-button {
  display: none;
}

textarea.normal-padding,
input[type='text'].normal-padding,
input[type='password'].normal-padding {
  padding: 12px 16px !important;
}

.input-color-transparent select {
  color: transparent !important;
}

.Mui-focused.input-color-transparent select {
  color: #000000 !important;
}

ion-label .MuiInput-underline:before {
  content: none;
}

ion-label p {
  font-size: 16px !important;
}

.ticket-step-input .MuiNativeSelect-icon,
.row-input .MuiNativeSelect-icon {
  transform: translateY(0px) translateX(-8px);
  right: 0px !important;
}

.ticket-step-input .MuiInput-underline:before,
.row-input .MuiInput-underline:before {
  content: none;
}
.ticket-step-input .MuiInputBase-input,
.row-input .MuiInputBase-input {
  padding: 8px 30px 8px 12px !important;
  font-size: 16px;
}

.ticket-step-input .MuiAutocomplete-endAdornment,
.row-input .MuiAutocomplete-endAdornment {
  transform: translateX(-8px);
}

.MuiTablePagination-toolbar p {
  margin-bottom: 0 !important;
}
.MuiTablePagination-menuItem {
  background-color: white !important;
}
.MuiAutocomplete-popper {
  background-color: white !important;
}
.place-item-header {
  margin-bottom: 8px;
}
.place-item-input {
  height: 10px;
}
.place-item-errortext {
  font-size: 12px;
  position: absolute;
  bottom: -16px;
}

.attachment-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: unset;
}

.attachment-image {
  width: 100%;
  height: auto;
  max-width: unset;
}

.loading,
.loading ion-backdrop {
  pointer-events: none;
}

.loading .loading-wrapper {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16),
    0px 1px 2px rgba(8, 35, 48, 0.24);
  background-color: #ffffff;
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  color: #000000;
  pointer-events: all;
}

.MuiPaper-root.MuiPaper-rounded {
  overflow: hidden;
}

.accessory-info {
  border-bottom: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
}

.file-upload-thumbnail {
  cursor: pointer;
  background-color: var(
    --ion-tab-bar-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
  );
  position: relative;
}

.file-upload-thumbnail ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 24px;
}

.question-number {
  position: absolute;
  top: 48px;
  left: 16px;
}

.question-label {
  margin-right: 80px;
}

.question-remove {
  position: absolute;
  top: 48px;
  right: 43px;
}

.question-reorder {
  position: absolute;
  top: 53px;
  right: 16px;
}

.flow-number {
  position: absolute;
  top: 16px;
  left: 16px;
}

.ion-weight-500 {
  font-weight: 500;
}

.contact-name-icon-wrap {
  height: 24px;
  width: 24px;
}

.contact-name-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.file-input-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.file-input-wrap p {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login {
  padding: 16px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-light);
}

.login .MuiPaper-root {
  padding: 16px;
  width: 100%;
  max-width: 320px;
  background-color: #ffffff !important;
}

.login-wrap {
  width: 100%;
}

.login p {
  font-size: 12px;
}

.pw-wrap {
  position: relative;
}

.pw-wrap ion-button {
  position: absolute;
  top: 0;
  right: 0;
}

.MuiTouchRipple-root {
  display: none;
}

.no-connection-background {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 111111;
  background-color: rgba(255, 255, 255, 0.6);
}

.no-connection-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1111111111;
  text-align: center;
  border-top: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  padding: 4px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
}

.MuiTypography-colorPrimary {
  color: var(--ion-color-primary) !important;
}

.fm-service-container {
  pointer-events: none;
  opacity: 0;
  transition: all 0s;
  transform: translateY(20px);
}

.fm-service-container.is-active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0px);
  transition: all 0.35s ease-in-out;
}

.MuiDivider-root {
  background-color: var(
    --ion-tab-bar-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
  ) !important;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

img.no-data {
  width: 0px;
  height: 0px;
  position: relative;
}

img.no-data:after {
  position: absolute;
  left: 0;
  top: 0;
  content: 'No data';
  width: 100px;
  color: var(--ion-color-medium);
}

.monitoring-section {
  background-color: rgba(215, 216, 218, 0.35);
  border-radius: 4px;
}

.errors-table {
  max-width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #f1f1f2;
  background-color: #fff;
  border: 2px solid #f5f6f9;
  width: 100%;
}

.errors-table th,
.errors-table td {
  /* border: 1px solid #f1f1f2; */
  border: 2px solid #f5f6f9;
  padding: 16px;
}
.errors-table th {
  text-align: left;
  font-weight: 400;
}

.errors-table th p,
.errors-table td p {
  font-size: 16px !important;
  margin-bottom: 0 !important;
  font-weight: normal;
}

.errors-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.alert-wrapper {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16),
    0px 1px 2px rgba(8, 35, 48, 0.24) !important;
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  padding: 16px;
}

.alert-wrapper .alert-head {
  padding: 0;
}

.alert-wrapper .alert-message {
  margin-bottom: 16px;
  padding: 0;
}

.alert-wrapper .alert-button-group {
  padding: 0;
}

.alert-wrapper .alert-button-group button:last-child {
  margin-right: 0;
}

.build-info {
  z-index: 111;
  font-size: 12px;
  pointer-events: none;
  opacity: 0.4 !important;
  padding: 8px 16px;
}

.build-info p {
  margin-bottom: 0 !important;
  pointer-events: none;
}

.logo-wrap {
  position: fixed;
  left: 48px;
  top: 10px;
  z-index: 1111;
  width: 64px;
  height: 46px;
}

.logo {
  width: 56px;
  height: 40px;
}

.logo-white {
  opacity: 0;
  position: absolute;
}

.logo-black {
  opacity: 1;
  position: relative;
}

.question-wrap {
  overflow: hidden;
}

.task-title {
  max-width: calc(50vw - 500px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-row {
  opacity: 0.5;
}

.monitoring-row {
  display: flex !important;
  overflow-y: scroll;
}

.monitoring-row-item {
  width: 300px;
  flex-shrink: 0;
  padding-bottom: 32px;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000000;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000000;
}

.triangle-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.error-triangle-wrap {
  float: right;
  cursor: pointer;
}
.error-triangle-wrap:after {
  display: inline-block;
  content: '';
  margin-left: 10px;
  margin-bottom: 3px;
}
.error-triangle-wrap.error-triangle-down:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000000;
}
.error-triangle-wrap.error-triangle-up:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000000;
}

.triangle-wrap .triangle-up,
.triangle-wrap .triangle-down {
  margin-left: 8px;
  top: calc(50% - 3px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
}

ol li::marker {
  font-weight: 500;
}

.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
}

.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  height: 500px;
}

.responsive-iframe-container ion-slides {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.responsive-iframe-container .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.circle-image-list {
  display: flex;
  max-width: 100%;
  width: 100%;
  overflow-y: scroll;
}

.circle-image {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--ion-color-light);
  cursor: pointer;
}

.circle-image:not(:first-of-type) {
  margin-left: -20px;
}

.circle-image img {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

/*
* New styles for EV-variant
*----------------------------------------------------------------------------------------------------------------------------------
*/
.tickets-table .MuiFormControl-root,
.contacts-table .MuiFormControl-root,
.accessories-table .MuiFormControl-root,
.places-table .MuiFormControl-root {
  display: flex;
}
.tickets-table .tickets-table-head .MuiInputLabel-shrink,
.contacts-table .contacts-table-head .MuiInputLabel-shrink,
.accessories-table .accessories-table-head .MuiInputLabel-shrink,
.places-table .places-table-head .MuiInputLabel-shrink {
  display: none;
}
.tickets-table .tickets-table-head th,
.contacts-table .contacts-table-head th,
.accessories-table .accessories-table-head th,
.places-table .places-table-head th {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.tickets-table .tickets-table-head th div,
.contacts-table .contacts-table-head th div,
.accessories-table .accessories-table-head th div,
.places-table .places-table-head th div {
  height: 44px;
  margin: 0 !important;
}
.tickets-table .tickets-table-head label.MuiInputLabel-formControl,
.contacts-table .contacts-table-head label.MuiInputLabel-formControl,
.accessories-table .accessories-table-head label.MuiInputLabel-formControl,
.places-table .places-table-head label.MuiInputLabel-formControl {
  transform: translate(0, 16px) scale(1);
  font-weight: 500;
}
.tickets-table .tickets-table-head th {
  background-color: #fafafa;
  border: none !important;
  padding: 0.5rem;
}
.tickets-table tr.manual-ikea {
  border-left: 5px solid #003b72;
}
.tickets-table tr.manual-linkapp {
  border-left: 5px solid #dddcdc;
}
.filter-active-select select {
  padding: 1rem;
  margin-top: 0 !important;
}
.filter-active-label {
  transform: translate(0.5rem, 1rem) scale(1) !important;
}
.color-explanations {
  display: grid;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
}
span.color-border-explanation {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
}
span.color-border-explanation:before {
  content: ' ';
  width: 5px;
  height: 20px;
  margin-right: 5px;
}
span.color-border-explanation.exp-manual:before {
  background-color: #dddcdc;
}
span.color-border-explanation.exp-manual-ikea:before {
  background-color: #003b72;
}
.status-badge-select {
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.status-badge-select ul {
  padding: 7.5px 8px;
}
.status-badge-select ul li span {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-right: 0.5rem;
}
.status-badge-select {
}
.status-badge-select .status-badge-color {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
}
.status-badge-select.status-color-open .status-badge-color {
  background-color: #e13c45;
}
.status-badge-select.status-color-analysis_started .status-badge-color {
  background-color: #fdcd8b;
}
.status-badge-select.status-color-analysis_finished .status-badge-color {
  background-color: #ffa431;
}
.status-badge-select.status-color-in_field_maintenance .status-badge-color {
  background-color: #d2eee9;
}
.status-badge-select.status-color-repair_started .status-badge-color {
  background-color: #5dc1b4;
}
.status-badge-select.status-color-repair_finished .status-badge-color {
  background-color: #008c76;
}
.status-badge-select.status-color-waiting_for_parts .status-badge-color {
  background-color: #c1bdfe;
}
.status-badge-select.status-color-waiting_for_resourcing .status-badge-color {
  background-color: #c1bdfe;
}
.status-badge-select.status-color-manufacturer .status-badge-color {
  background-color: #564cfa;
}
.status-badge-select.status-color-documented .status-badge-color {
  background-color: #2e96fb;
}
.status-badge-select.status-color-closed .status-badge-color {
  background-color: #a2cffe;
}
.status-badge-select.status-color-suspended .status-badge-color {
  background-color: #a2cffe;
}

#menu-ticket_status ul {
  background-color: #fff;
}
#menu-ticket_status ul li {
  padding: 0.5rem 8px;
  margin: 0.5rem;
  display: flex;
  cursor: pointer;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
#menu-ticket_status ul li span {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-right: 0.5rem;
}
ion-badge.status-badge {
  display: inline-block;
  font-weight: normal;
  font-size: 16px !important;
}
ion-badge.status-badge span.status-badge-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: bottom;
}
.status-badge-open .status-badge-color {
  background-color: #e13c45;
}
.status-badge-analysis_started .status-badge-color {
  background-color: #fdcd8b;
}
.status-badge-analysis_finished .status-badge-color {
  background-color: #ffa431;
}
.status-badge-in_field_maintenance .status-badge-color {
  background-color: #d2eee9;
}
.status-badge-repair_started .status-badge-color {
  background-color: #5dc1b4;
}
.status-badge-repair_finished .status-badge-color {
  background-color: #008c76;
}
.status-badge-waiting_for_parts .status-badge-color {
  background-color: #c1bdfe;
}
.status-badge-waiting_for_resourcing .status-badge-color {
  background-color: #c1bdfe;
}
.status-badge-manufacturer .status-badge-color {
  background-color: #564cfa;
}
.status-badge-documented .status-badge-color {
  background-color: #2e96fb;
}
.status-badge-closed .status-badge-color {
  background-color: #a2cffe;
}
.status-badge-suspended .status-badge-color {
  background-color: #a2cffe;
}

.control-room-tickets {
  width: auto;
  min-width: 480px;
  display: block;
  max-width: 480px;
  background-color: var(--ion-color-light);
}

.control-room-ticket-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 0.9rem;
  background-color: #fff;
}
.control-room-ticket-wrapper.faulted {
  border-left: 8px solid #eb024c;
}

.control-room-ticket-wrapper ion-row {
  align-items: center;
  margin: 4px 0 0 0;
}
.control-room-ticket-wrapper * {
  font-size: 16px !important;
}

.control-room-ticket-wrapper ion-row ion-icon {
  font-size: 18px !important;
}

.control-room-ticket-wrapper ion-row ion-col p {
  margin: 0 !important;
  display: inline-block;
}
.control-room-ticket-wrapper ion-row ion-col.datetime {
  color: var(--ion-color-medium);
}
.control-room-ticket-wrapper ion-row ion-col:nth-child(2) {
  text-align: right;
}
.control-room-ticket-wrapper ion-row ion-col ion-button {
  --padding-start: 4px !important;
  --padding-end: 4px !important;
}
.control-room-ticket-wrapper ion-row ion-col > ion-badge {
  --padding-start: 0px !important;
  display: flex;
}

.control-room-ticket-wrapper ion-icon {
  color: var(--ion-color-medium);
}

.control-room-ticket-wrapper ion-badge {
  background-color: transparent;
  color: #000;
  font-weight: normal;
}
.control-room-ticket-wrapper ion-badge.ion-color-primary {
  background-color: #3880ff;
  color: #fff;
}
.control-room-ticket-wrapper ion-badge.ion-color-error {
  background-color: #c5000f;
  color: #fff;
}

.control-room-ticket-wrapper ion-badge span {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-right: 0.5rem;
}
.control-room-ticket-wrapper ion-badge.status-badge-open .status-badge-color {
  background-color: #e13c45;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-analysis_started
  .status-badge-color {
  background-color: #fdcd8b;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-analysis_finished
  .status-badge-color {
  background-color: #ffa431;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-in_field_maintenance
  .status-badge-color {
  background-color: #d2eee9;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-repair_started
  .status-badge-color {
  background-color: #5dc1b4;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-repair_finished
  .status-badge-color {
  background-color: #008c76;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-waiting_for_parts
  .status-badge-color {
  background-color: #c1bdfe;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-waiting_for_resourcing
  .status-badge-color {
  background-color: #c1bdfe;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-manufacturer
  .status-badge-color {
  background-color: #564cfa;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-documented
  .status-badge-color {
  background-color: #2e96fb;
}
.control-room-ticket-wrapper ion-badge.status-badge-closed .status-badge-color {
  background-color: #a2cffe;
}
.control-room-ticket-wrapper
  ion-badge.status-badge-suspended
  .status-badge-color {
  background-color: #a2cffe;
}

.control-room-ticket-modal #ion-react-wrapper {
  padding: 0 1rem 1rem 1rem;
}

.control-room-monitoring {
  /* background-color: var(--ion-color-light); */
}
.control-room-monitoring-header {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  height: 64px !important;
}
.control-room-monitoring-header ion-col ion-item {
  --border-style: none;
}
.control-room-monitoring-header.multiscreen {
  height: 45px !important;
}
.control-room-monitoring-header > ion-col {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}
.control-room-monitoring-header ion-col * {
  margin: 0 !important;
}
.control-room-monitoring-header ion-col.md:nth-child(1) {
  justify-content: flex-start;
}
.control-room-monitoring-header ion-col.md:nth-child(2) {
  justify-content: flex-end;
  flex-grow: 0;
}
.control-room-tickets .control-room-monitoring-header ion-col.md:nth-child(2) {
  flex-grow: 1;
}
.control-room-tickets .control-room-monitoring-header ion-col.md:nth-child(3) {
  flex-grow: 0;
}
.control-room-monitoring-header .screen-icons {
  display: block;
  width: 100px;
}
.control-room-monitoring-header .screen-icons .screen-icon,
.control-room-monitoring-header .screen-icons .multi-screen-icon {
  opacity: 1;
  position: relative;
}
.control-room-monitoring-header .screen-icons ion-segment-button {
  background-color: #e6f2ff;
  --indicator-height: 0px;
  min-width: auto;
  width: 37px !important;
  display: inline-block;
  min-height: auto !important;
  padding-top: 6px;
  padding-bottom: 6px;
}
.control-room-monitoring-header
  .screen-icons
  ion-segment-button::part(indicator) {
  height: 0px;
}
.control-room-monitoring-header .screen-icons ion-segment-button:nth-child(1) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.control-room-monitoring-header .screen-icons ion-segment-button:nth-child(2) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.control-room-monitoring-header
  .screen-icons
  ion-segment-button.segment-button-checked {
  background-color: var(--ion-color-primary);
  color: white;
}
.control-room-monitoring-header
  .screen-icons
  ion-segment-button
  .screen-icon
  path {
  fill: #999ba1;
}
.control-room-monitoring-header
  .screen-icons
  ion-segment-button.segment-button-checked
  .screen-icon
  path {
  fill: white;
}

.control-room-monitoring .grafana-monitoring-panels {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
.control-room-monitoring .grafana-monitoring-panels .grafana-monitoring-panel {
  background-color: #fff;
  padding: 1rem;
}
.control-room-monitoring .grafana-monitoring-panel-select {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100% !important;
  background-color: var(--ion-color-light);
}
.control-room-monitoring .grafana-monitoring-panel-select * {
  display: block;
  margin: 0 !important;
  font-size: 16px;
}
.control-room-monitoring .grafana-monitoring-panel-select ion-icon {
  font-size: 1.6em;
}

ion-header {
  box-shadow: 0px 2px 6px rgb(8 35 48 / 16%), 0px 1px 2px rgb(8 35 48 / 24%);
}
.background-light-gray {
  background-color: #f5f6f9;
}
.ticket-data-table ion-col ion-row ion-col {
  background-color: #fff;
  border: 2px solid #f5f6f9;
  padding: 0.5rem 1rem;
}
.ticket-data-table ion-col p {
  margin: 0 !important;
}

.ticket-data-table .ticket-error-datetime,
.MuiButtonBase-root.small-accordion,
.ticket-data-table .ticket-error-satellite-summary {
  flex-direction: row-reverse;
  background-color: #fff;
}
.ticket-data-table .transaction-details,
.MuiButtonBase-root.small-accordion .small-accordion-details {
  background-color: #fff;
  margin-top: 1rem;
}
.ticket-error-satellite .ticket-error-satellite-container {
  background-color: #fff;
}
.ticket-data-table .transaction-details ion-col,
.ticket-error-satellite ion-col {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border: none;
}
.ticket-data-table .text-medium {
  color: var(--ion-color-medium);
}
.ticket-data-table ion-row.color-medium {
  color: var(--ion-color-medium);
}
.ticket-data-table ion-row.color-medium ion-col {
  background-color: var(--ion-color-light);
}
table.tickets-station-alerts-table {
  background-color: #fff;
}
table.tickets-station-alerts-table .MuiTableCell-root.MuiTableCell-body {
  border: none !important;
  padding: 8px !important;
  font-size: 16px;
  color: var(--ion-color-medium) !important;
}
.alert-message-channel-icons {
  float: right;
  width: auto;
}
.alert-message-channel-icons .screen-icons {
  display: block;
  width: 100px;
}
.alert-message-channel-icons .screen-icons .teams-icon,
.alert-message-channel-icons .screen-icons .mail-icon {
  opacity: 1;
  position: relative;
  margin: 0;
}
.alert-message-channel-icons .screen-icons ion-segment-button {
  background-color: #e6f2ff;
  --indicator-height: 0px;
  min-width: auto;
  width: 37px !important;
  display: inline-block;
  min-height: auto !important;
  padding-top: 6px;
  padding-bottom: 6px;
}
.alert-message-channel-icons .screen-icons ion-segment-button::part(indicator) {
  height: 0px;
}
.alert-message-channel-icons .screen-icons ion-segment-button:nth-child(1) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.alert-message-channel-icons .screen-icons ion-segment-button:nth-child(2) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.alert-message-channel-icons
  .screen-icons
  ion-segment-button.segment-button-checked {
  background-color: var(--ion-color-primary);
}
.alert-message-channel-icons .screen-icons ion-segment-button ion-icon {
  color: #999ba1 !important;
}
.alert-message-channel-icons
  .screen-icons
  ion-segment-button.segment-button-checked
  ion-icon {
  color: white !important;
}
.location-coordinates {
  flex-direction: column;
}
.place-stations-accordion .MuiCollapse-container {
  background-color: #f5f6f9;
}
.place-stations-accordion .place-data-table ion-col {
  background-color: #fff;
  border: 2px solid #f5f6f9;
  padding: 0.5rem 1rem;
}
.place-stations-accordion .place-data-table input {
  padding: 0;
}
.place-stations-accordion .MuiTextField-root {
  width: 100%;
}
.station-panel-remove-icon {
  align-self: flex-end;
}
.station-panel-add-connector-icon {
  float: right;
}
.station-panel-modal #ion-react-wrapper {
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12) !important;
  background: white !important;
  overflow: auto;
  width: auto;
  max-height: 750px;
  height: 60vh;
}

.station-panel-modal .modal-wrapper .ion-page {
  padding: 0 16px 16px 16px;
}

.station-panel-modal .modal-wrapper .ion-page .table-wrap {
  padding-top: 8px;
  max-height: calc(100% - 52px);
  overflow-y: scroll;
}
.station-panel-modal .modal-wrapper {
  width: 75%;
  max-width: 1250px;
}
.station-panel-modal .grafana-panel-ids {
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 1px solid gray;
}

.multiscreen-monitoring-page {
  background-color: #fff;
  z-index: 9999 !important;
}
.alert-success {
  background-color: #d2eee9;
  border-radius: 4px;
}

.alert-success h5 {
  color: #015a4c;
  padding: 4px 8px;
}
.add-contact-button {
  text-decoration: underline;
  color: var(--ion-color-primary);
  background: none;
  margin: 0 0 1rem 0;
  padding: 0;
}

.device-management-stations ion-button:hover {
  /* border: 2px solid var(--color-hover) */
  -webkit-box-shadow: inset 0px 0px 0px 2px var(--color-hover);
  -moz-box-shadow: inset 0px 0px 0px 2px var(--color-hover);
  box-shadow: inset 0px 0px 0px 2px var(--color-hover);
}
.ticket-monitoring-panels.panel-type-uc {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  grid-template-rows: 400px;
  grid-template-columns: repeat(9, 175px);
  grid-template-areas:
    'a a b c d d d e f'
    'a a b c d d d e f';
  grid-gap: 0.5rem;
}
.ticket-monitoring-panels.panel-type-uc .panel_station_status {
  grid-area: a;
}
.ticket-monitoring-panels.panel-type-uc .panel_station_amps {
  grid-area: b;
}
.ticket-monitoring-panels.panel-type-uc .panel_station_kw {
  grid-area: c;
}
.ticket-monitoring-panels.panel-type-uc .panel_station_kwh {
  grid-area: d;
}
.ticket-monitoring-panels.panel-type-uc .panel_station_total_kwh {
  grid-area: e;
}
.ticket-monitoring-panels.panel-type-uc .panel_station_total_time {
  grid-area: f;
}

.ticket-monitoring-panels object,
.place-monitoring-panels object {
  height: 100%;
  width: 100%;
}

.ticket-monitoring-panels.panel-type-kempower {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: scroll;
  grid-template-rows: 200px;
  grid-template-columns: repeat(9, 175px);
  grid-template-areas:
    'a a a b b b b b b'
    'e f g h h h h h h';
  grid-gap: 0.5rem;
}
.ticket-monitoring-panels.panel-type-kempower .panel_station_status {
  grid-area: a;
}
.ticket-monitoring-panels.panel-type-kempower .panel_station_kw {
  grid-area: e;
}
.ticket-monitoring-panels.panel-type-kempower .panel_station_amps {
  grid-area: f;
}
.ticket-monitoring-panels.panel-type-kempower .panel_station_kwh {
  grid-area: g;
}
/* .place-monitoring-panels .panel_station_total_kwh { grid-area: e; }
.place-monitoring-panels .panel_station_total_time { grid-area: f; } */
.ticket-monitoring-panels.panel-type-kempower
  .ticket-monitoring-container-connector-panel.panel_station_connector_status {
  grid-area: b;
}
.ticket-monitoring-panels.panel-type-kempower
  .ticket-monitoring-container-connector-panel.panel_station_connector_kw {
  grid-area: f;
}
.ticket-monitoring-panels.panel-type-kempower
  .ticket-monitoring-container-connector-panel.panel_station_connector_kwh {
  grid-area: f;
}

.ticket-monitoring-panels.panel-type-kempower .connectors {
  grid-area: b;
  display: grid;
  grid-template-rows: 200px;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(6, minmax(120px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
}
.ticket-monitoring-panels.panel-type-kempower
  .connectors
  .panel_station_connector_status {
  grid-column-end: span 3;
}
.ticket-monitoring-panels.panel-type-kempower
  .connectors
  .panel_station_connector_kwh {
  grid-column-end: span 2;
}
.ticket-monitoring-panels.panel-type-kempower
  .connectors
  .panel_station_connector_kw {
  order: 3;
}

.ticket-monitoring-panels-inner.panel-type-ocpp {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: scroll;
  grid-template-rows: 200px;
  grid-template-columns: repeat(8, 175px);
  grid-template-areas:
    'a a b b b c d d'
    'a a e f g h d d';
  grid-gap: 0.5rem;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_status {
  grid-area: a;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_kwh {
  grid-area: b;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_voltage {
  grid-area: e;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_amps {
  grid-area: f;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_kw {
  grid-area: g;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_duration {
  grid-area: c;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_soc {
  grid-area: h;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_temperature {
  grid-area: d;
}

.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_status {
  grid-column-end: span 2;
  grid-row-end: span 2;
}
.ticket-monitoring-panels.panel-type-ocpp .panel_station_connector_kwh {
  grid-column-end: span 3;
}

.place-monitoring-panels {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  grid-template-rows: 400px;
  grid-template-columns: repeat(8, 175px);
  grid-template-areas:
    'a a a b b b c c d'
    'e f g h h h i i j';
  grid-gap: 0.5rem;
}
.place-monitoring-panels .panel_station_status {
  grid-area: a;
}
.place-monitoring-panels .panel_station_kw {
  grid-area: e;
}
.place-monitoring-panels .panel_station_amps {
  grid-area: f;
}
.place-monitoring-panels .panel_station_kwh {
  grid-area: g;
}
/* .place-monitoring-panels .panel_station_total_kwh { grid-area: e; }
.place-monitoring-panels .panel_station_total_time { grid-area: f; } */
.place-monitoring-panels
  .place-monitoring-container-connector-panel.panel_station_connector_status {
  grid-area: b;
}
.place-monitoring-panels
  .place-monitoring-container-connector-panel.panel_station_connector_kw {
  grid-area: f;
}
.place-monitoring-panels
  .place-monitoring-container-connector-panel.panel_station_connector_kwh {
  grid-area: f;
}

.place-monitoring-panels.panel-type-uc {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  grid-template-rows: 400px;
  grid-template-columns: repeat(9, 175px);
  grid-template-areas:
    'a a b c d d d e f'
    'a a b c d d d e f';
  grid-gap: 0.5rem;
}
.place-monitoring-panels.panel-type-uc .panel_station_status {
  grid-area: a;
}
.place-monitoring-panels.panel-type-uc .panel_station_amps {
  grid-area: b;
}
.place-monitoring-panels.panel-type-uc .panel_station_kw {
  grid-area: c;
}
.place-monitoring-panels.panel-type-uc .panel_station_kwh {
  grid-area: d;
}
.place-monitoring-panels.panel-type-uc .panel_station_total_kwh {
  grid-area: e;
}
.place-monitoring-panels.panel-type-uc .panel_station_total_time {
  grid-area: f;
}

.ticket-device-management .transaction-summary-datetime,
.place-device-management .transaction-summary-datetime,
.MuiButtonBase-root.small-accordion {
  flex-direction: row-reverse;
  background-color: #fff;
}
.ticket-device-management .transaction-details,
.place-device-management .transaction-details,
.MuiButtonBase-root.small-accordion .small-accordion-details {
  background-color: #fff;
  margin-top: 1rem;
}
.ticket-device-management .transaction-details ion-col,
.place-device-management .transaction-details ion-col,
.MuiButtonBase-root.small-accordion
  .small-accordion-details
  .place-data-table
  ion-col {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border: none;
}
.ticket-device-management .transaction-details ion-col:nth-child(1),
.place-device-management .transaction-details ion-col:nth-child(1),
.MuiButtonBase-root.small-accordion
  .small-accordion-details
  .place-data-table
  ion-col:nth-child(1) {
  font-weight: bold;
}
.ticket-device-management .transaction-details ion-col:nth-child(2),
.place-device-management .transaction-details ion-col:nth-child(2),
.MuiButtonBase-root.small-accordion
  .small-accordion-details
  .place-data-table
  ion-col:nth-child(2) {
  font-weight: 400;
}
.ticket-device-management .transaction-details ion-col p,
.place-device-management .transaction-details ion-col p,
.MuiButtonBase-root.small-accordion
  .small-accordion-details
  .place-data-table
  ion-col
  p {
  margin: 0 !important;
}
ion-modal #ion-react-wrapper {
  overflow: auto;
  padding-bottom: 1rem;
}
ion-modal #ion-react-wrapper ion-button::part(native) {
  padding: 1rem 2rem;
  width: auto;
  display: inline-block;
  margin-top: 0.5rem;
}
ion-modal #ion-react-wrapper ion-badge {
  color: var(--ion-text-color);
}
.sc-ion-alert-md-h {
  --max-width: 400px;
}

/*
* IKEA UI
* ---------------------------------------------------------------------------------------------------------------------------------
*/
.ion-page.ikea ion-content {
  --background: #fafafa;
}
.ikea-place-select {
  background-color: #fff;
}
.ikea-place-select select {
  padding: 0.65rem;
  margin-top: 0 !important;
  box-shadow: 0px 2px 4px 0px #4040401a;
  width: 400px !important;
}
.ikea-info-box {
  background: rgba(244, 67, 54, 0.1);
  color: #000;
  padding: 8px 0px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  --min-height: 16px;
}
.ikea-info-box ion-icon {
  margin-top: 0;
  margin-bottom: 0;
}
.ikea-info-box .phone-number {
  font-weight: 400;
}
.ikea-report-button {
  background: #fd4f05;
  color: #fff;
  padding: 10px 16px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 22px;
  margin: 0;
  height: 40px;
}
.ikea .ikea-segment-select {
  gap: 16px;
}
.charging-graphs ion-card h5 {
  color: black;
}
.ikea-monitoring-container-panel:not(.connectors) {
  border: 1px solid #d7d8da80;
  border-radius: 3px;
}
.ikea-monitoring-container-panel.connectors object {
  border: 1px solid #d7d8da80;
  border-radius: 3px;
}
.ikea-monitoring-panels.panel-type-uc {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  grid-template-rows: 400px;
  grid-template-columns: repeat(9, 175px);
  grid-template-areas:
    'a a b c d d d e f'
    'a a b c d d d e f';
  grid-gap: 1rem;
}
.ikea-monitoring-panels.panel-type-uc .panel_station_status {
  grid-area: a;
}
.ikea-monitoring-panels.panel-type-uc .panel_station_amps {
  grid-area: b;
}
.ikea-monitoring-panels.panel-type-uc .panel_station_kw {
  grid-area: c;
}
.ikea-monitoring-panels.panel-type-uc .panel_station_kwh {
  grid-area: d;
}
.ikea-monitoring-panels.panel-type-uc .panel_station_total_kwh {
  grid-area: e;
}
.ikea-monitoring-panels.panel-type-uc .panel_station_total_time {
  grid-area: f;
}

.ikea-monitoring-panels object,
.place-monitoring-panels object {
  height: 100%;
  width: 100%;
}

.ikea-monitoring-panels.panel-type-kempower {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: auto;
  grid-template-rows: 127px;
  grid-template-columns: repeat(9, 175px);
  grid-template-areas:
    'a a a b b b b b b'
    'e f g h h h h h h';
  grid-gap: 1rem;
}
.ikea-monitoring-panels.panel-type-kempower.number-of-connectors-3,
.ikea-monitoring-panels.panel-type-kempower.number-of-connectors-4 {
  grid-template-areas:
    'a a a b b b b b b'
    'e f g h h h h h h'
    'i i i k k k k k k'
    'j j j l l l l l l';
  grid-template-rows: 450px;
}
.ikea-monitoring-panels.panel-type-kempower.number-of-connectors-5,
.ikea-monitoring-panels.panel-type-kempower.number-of-connectors-6 {
  grid-template-areas:
    'a a a b b b b b b'
    'e f g h h h h h h'
    'i i i k k k k k k'
    'j j j l l l l l l'
    'm m m n n n n n n'
    'o o o p p p p p p';
  grid-template-rows: 480px;
}
.ikea-monitoring-panels.panel-type-kempower .panel_station_status {
  grid-area: a;
}
.ikea-monitoring-panels.panel-type-kempower .panel_station_kw {
  grid-area: e;
}
.ikea-monitoring-panels.panel-type-kempower .panel_station_amps {
  grid-area: f;
}
/* .ikea-monitoring-panels.panel-type-kempower .panel_station_kwh { grid-area: g;} */
.ikea-monitoring-panels.panel-type-kempower .panel_station_ratio {
  grid-area: g;
}
/* .place-monitoring-panels .panel_station_total_kwh { grid-area: e; }
.place-monitoring-panels .panel_station_total_time { grid-area: f; } */
.ikea-monitoring-panels.panel-type-kempower
  .ikea-monitoring-container-connector-panel.panel_station_connector_status {
  grid-area: b;
}
.ikea-monitoring-panels.panel-type-kempower
  .ikea-monitoring-container-connector-panel.panel_station_connector_kw {
  grid-area: f;
}
.ikea-monitoring-panels.panel-type-kempower
  .ikea-monitoring-container-connector-panel.panel_station_connector_kwh {
  grid-area: f;
}

.ikea-monitoring-panels.panel-type-kempower .connectors {
  grid-area: b;
  display: grid;
  grid-template-rows: 135px;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(6, minmax(120px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
}
.ikea-monitoring-panels.panel-type-kempower
  .connectors
  .panel_station_connector_status {
  grid-column-end: span 3;
}
.ikea-monitoring-panels.panel-type-kempower
  .connectors
  .panel_station_connector_kwh {
  grid-column-end: span 2;
}
.ikea-monitoring-panels.panel-type-kempower
  .connectors
  .panel_station_connector_kw {
  order: 3;
}

.ikea-monitoring-panels-inner.panel-type-ocpp {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: auto;
  grid-template-rows: 200px;
  grid-template-columns: repeat(8, 175px);
  grid-template-areas:
    'a a b b b c d d'
    'a a e f g h d d';
  grid-gap: 0.5rem;
  margin-bottom: 1rem;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_status {
  grid-area: a;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_kwh {
  grid-area: b;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_voltage {
  grid-area: e;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_amps {
  grid-area: f;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_kw {
  grid-area: g;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_duration {
  grid-area: c;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_soc {
  grid-area: h;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_temperature {
  grid-area: d;
}

.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_status {
  grid-column-end: span 2;
  grid-row-end: span 2;
}
.ikea-monitoring-panels.panel-type-ocpp .panel_station_connector_kwh {
  grid-column-end: span 3;
}
.ikea .ikea-table-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.ikea .ikea-table-buttons ion-col {
  /* align-self: center; */
}
.ikea .ikea-table-buttons ion-col.shrink-width {
  flex-grow: 0;
}
.ikea .reports-table .MuiTableCell-body {
  font-size: 14px !important;
  padding: 13px 18px !important;
  color: unset !important;
  text-decoration: none;
  background-color: #fff;
}
.ikea .reports-table .reports-table-head th {
  background-color: #fafafa;
  border: none !important;
  padding: 8px 13px;
}
.ikea
  .reports-table
  .reports-table-head
  .MuiTableCell-root.MuiTableCell-head:has(.active-sort) {
  background-color: #ddd;
}

.ikea .ikea-events-data .events-data-table ion-row ion-col {
  background-color: #fff;
  border: 2px solid #f5f6f9;
  padding: 0.5rem 1rem;
}
.ikea .ikea-events-data .events-data-table ion-col p {
  margin: 0 !important;
}

.ikea .ikea-events-data .events-data-table .ticket-error-datetime,
.MuiButtonBase-root.small-accordion {
  flex-direction: row-reverse;
  background-color: #fff;
}
.ikea .ikea-events-data .events-data-table .transaction-details,
.MuiButtonBase-root.small-accordion .small-accordion-details {
  background-color: #fff;
  margin-top: 1rem;
}
.ikea .ikea-events-data .events-data-table .transaction-details ion-col {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border: none;
}
.ikea .ikea-events-data .events-data-table .text-medium {
  color: var(--ion-color-medium);
}
.ikea .ikea-events-data .events-data-table ion-row.color-medium {
  color: var(--ion-color-medium);
}
.ikea .ikea-events-data .events-data-table ion-row.color-medium ion-col {
  background-color: var(--ion-color-light);
}
.ikea .status-badge {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  display: inline-block;
}
.ikea .status-badge.status-badge-color-green {
  background-color: #008c76;
}
.ikea .status-badge.status-badge-color-red {
  background-color: #e13c45;
}
.ikea .ikea-events-data .MuiAccordionSummary-root ion-text {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.ikea .ikea-tickets .tickets-table .MuiTableCell-body {
  font-size: 16px !important;
  padding: 18px 13px !important;
  color: unset !important;
  text-decoration: none;
  background-color: #fff;
}
.ikea .ikea-tickets .tickets-table .status-badge-select {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  background-color: white;
  display: flex;
  padding: 6px 0 7px;
}
#modal-new-ticket,
#modal-edit-ticket {
  --height: 75vh;
  --width: 50vw;
}
#modal-new-ticket #ion-react-wrapper,
#modal-edit-ticket #ion-react-wrapper {
  max-height: none;
}
#modal-new-ticket ion-row.half ion-item,
#modal-edit-ticket ion-row.half ion-item {
  width: calc(50% - 8px);
}
#modal-new-ticket
  #ion-react-wrapper
  ion-row.half
  ion-button.delete-button::part(native),
#modal-edit-ticket
  #ion-react-wrapper
  ion-row.half
  ion-button.delete-button::part(native) {
  margin: 0 !important;
  padding: 0 !important;
}
#modal-new-ticket .ikea-modal-place-select,
#modal-edit-ticket .ikea-modal-place-select {
  width: 100% !important;
}
#modal-new-ticket .file-upload,
#modal-edit-ticket .file-upload {
  width: 100%;
}
#modal-new-ticket .file-upload .file-input-container,
#modal-edit-ticket .file-upload .file-input-container {
  flex-direction: row;
  justify-content: space-around;
}
#modal-new-ticket .file-upload .file-input-container .file-input-wrap p,
#modal-edit-ticket .file-upload .file-input-container .file-input-wrap p {
  width: auto;
}
#modal-new-ticket .ikea-form-accordion,
#modal-edit-ticket .ikea-form-accordion {
  padding: 0.5rem;
  border: 1px solid #e2e2e2;
}
#modal-new-ticket .ikea-form-accordion .MuiAccordionSummary-content ion-text,
#modal-edit-ticket .ikea-form-accordion .MuiAccordionSummary-content ion-text {
  font-weight: bold;
}
#modal-new-ticket .ikea-form-accordion .severity-specifications,
#modal-edit-ticket .ikea-form-accordion .severity-specifications {
  padding: 0.5rem 0;
}
#modal-new-ticket .ikea-form-accordion .severity-specifications p,
#modal-edit-ticket .ikea-form-accordion .severity-specifications p {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 1rem;
  margin-bottom: 1rem !important;
  margin-top: 0.5rem !important;
}
#modal-new-ticket .ikea-form-accordion .severity-specifications p:last-child,
#modal-edit-ticket .ikea-form-accordion .severity-specifications p:last-child {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.pdf-document a {
  display: block;
  margin: 0 auto;
  width: 852px !important;
  height: 100% !important;
}
.pdf-document .react-pdf__Document {
  position: relative;
}
.pdf-document .react-pdf__Page__canvas {
  margin: 0 auto;
  margin-top: 5rem;
  width: 852px !important;
  height: 1200px !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 1rem;
}
.pdf-document .react-pdf__Page__textContent {
  width: calc(var(--scale-factor) * 820px) !important;
  height: calc(var(--scale-factor) * 1168px) !important;
  margin: 0 auto;
}
.pdf-document .page-controls {
  opacity: 1;
}

.pdf-document .page-controls {
  position: absolute;
  top: 1%;
  left: 50%;
  background: white;
  opacity: 1;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.pdf-document .page-controls span {
  font: inherit;
  font-size: 1.2em;
  padding: 0.5em;
}

.pdf-document .page-controls ion-button {
  cursor: pointer;
}

.pdf-document .page-controls ion-button:hover,
.pdf-document .page-controls ion-button:focus {
  background-color: #e6e6e6;
}
input.red-border,
.red-border {
  border: 1px solid red !important;
}

/*
* Global alert
* ---------------------------------------------------------------------------------------------------------------------------------
*/
.alert-modal {
  --height: auto;
  --width: auto;
}
.alert-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
  max-width: 1350px;
}
.alert-modal .ion-page .MuiTableRow-root .MuiTableCell-body {
  padding: 16px !important;
}

/*
* Dark Mode
* ---------------------------------------------------------------------------------------------------------------------------------
*/

body.dark .logo-white {
  opacity: 1;
  position: relative;
}

body.dark .logo-black {
  opacity: 0;
  position: absolute;
}

body.dark ion-list {
  background-color: #000000 !important;
}

body.dark ion-tab-bar {
  --border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  box-shadow: none;
}

body.dark ion-toolbar {
  border-bottom: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

body.dark ion-content {
  --background: #000000;
}

body.dark ion-title {
  --color: #ffffff;
}

body.dark ion-card {
  --background: #000000;
}

body.dark .profile ion-icon {
  color: #ffffff;
}

body.dark .mapboxgl-ctrl {
  background: #000000 !important;
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
}

body.dark .mapboxgl-ctrl-group button + button {
  border-top: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

body.dark .mapboxgl-ctrl-zoom-in span {
  background-image: url('./assets/add-dark-mode.svg') !important;
}

body.dark .mapboxgl-ctrl-zoom-out span {
  background-image: url('./assets/remove-dark-mode.svg') !important;
}

body.dark .mapboxgl-ctrl-geolocate-active span {
  background-image: url('./assets/locate-dark-mode.svg') !important;
}

body.dark .mapboxgl-ctrl-geolocate span {
  background-image: url('./assets/locate-dark-mode.svg') !important;
}

body.dark .profile p {
  margin-bottom: 0 !important;
  color: #ffffff !important;
}

body.dark .searchbar-input {
  background-color: var(
    --ion-tab-bar-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
  ) !important;
}

body.dark .MuiTableCell-body,
body.dark .MuiTableCell-head {
  color: #ffffff !important;
}

body.dark .MuiTableContainer-root {
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

body.dark .MuiNativeSelect-select {
  color: var(--ion-color-primary) !important;
}

body.dark .MuiNativeSelect-select::placeholder {
  color: var(--ion-color-primary) !important;
}

body.dark .MuiInputLabel-root,
body.dark .MuiInputLabel-root.Mui-focused {
  color: var(--ion-color-primary) !important;
  background-color: #000;
  z-index: 11;
  width: 100%;
  pointer-events: none;
}

body.dark .Mui-focused.input-color-transparent select {
  color: var(--ion-color-primary) !important;
}

body.dark .MuiNativeSelect-icon {
  color: var(--ion-color-primary) !important;
  z-index: 12;
}

body.dark .half-fixed-header {
  background-color: #000000 !important;
}

body.dark .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  color: #ffffff !important;
}

body.dark .MuiTablePagination-toolbar p {
  color: #ffffff !important;
}
body.dark .MuiTablePagination-toolbar {
  color: #ffffff !important;
}
body.dark .MuiTablePagination-actions button {
  color: #ffffff !important;
}
body.dark .MuiTablePagination-menuItem {
  background-color: black !important;
  color: white !important;
}
body.dark .MuiAutocomplete-root {
  margin-top: 8px;
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}
body.dark .MuiAutocomplete-inputRoot {
  color: white !important;
}
body.dark .MuiAutocomplete-input {
  color: white !important;
}
body.dark .MuiAutocomplete-clearIndicator {
  color: white !important;
}
body.dark .MuiAutocomplete-popupIndicator {
  color: white !important;
}
body.dark .MuiAutocomplete-popper {
  color: white !important;
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
  background-color: black !important;
}

body.dark .MuiInputBase-input:not(.MuiNativeSelect-root) {
  color: white !important;
}

body.dark .loading .loading-wrapper {
  background-color: #000000;
}

body.dark .loading .loading-wrapper .loading-content {
  color: #ffffff !important;
}

body.dark .MuiPaper-root.MuiPaper-rounded:not(.MuiAccordion-root) {
  border: 1px solid
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    ) !important;
}

.MuiAccordionSummary-expandIcon ion-icon {
  color: var(--ion-color-medium);
}

body.dark .no-connection-background {
  background-color: rgba(0, 0, 0, 0.6);
}

body.dark .no-connection-notice {
  color: #ffffff !important;
  background-color: #000000 !important;
}

body.dark .errors-table {
  box-shadow: 0 0 0 1px #272727;
}

body.dark .errors-table th,
body.dark .errors-table td {
  border: 1px solid #272727;
}

body.dark ion-label textarea:disabled,
body.dark ion-label input:disabled,
body.dark ion-label select:disabled,
body.dark .login input:disabled,
body.dark .ticket-step-input select:disabled,
body.dark .ticket-step-input textarea:disabled,
body.dark .ticket-step-input input:disabled,
body.dark .row-input select:disabled,
body.dark .row-input textarea:disabled,
body.dark .row-input input:disabled {
  color: rgba(255, 255, 255, 0.4) !important;
}

body.dark .mapboxgl-ctrl-attrib a {
  color: #ffffff !important;
}

body.dark .triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
}

body.dark .triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
}

body.dark .triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}

body.dark .circle-image {
  border-color: var(
    --ion-tab-bar-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
  );
}
body.dark .tickets-table .tickets-table-head th,
body.dark .tickets-station-alerts-table .tickets-table-head th,
body.dark .places-table .places-table-head th,
body.dark .contacts-table .contacts-table-head th,
body.dark .accessories-table .accessories-table-head th {
  background-color: transparent;
  border: none !important;
  padding: 0.5rem;
  color: var(--ion-color-primary) !important;
}
body.dark table.tickets-station-alerts-table {
  background-color: transparent;
}
body.dark #menu-ticket_status ul {
  background-color: #000000;
}
body.dark #menu-ticket_status ul li {
  padding: 0.5rem 8px;
  margin: 0.5rem;
  display: flex;
  cursor: pointer;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #fff !important;
}
body.dark #menu-ticket_status ul li span {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-right: 0.5rem;
}
body.dark #menu-ticket_status .MuiPaper-root {
  box-shadow: 0px 0px 4px 1px rgb(255 255 255);
}
body.dark .background-light-gray {
  background-color: #000;
}
body.dark ion-label textarea,
body.dark ion-label input,
body.dark ion-label select,
body.dark .login input,
body.dark .ticket-step-input select,
body.dark .ticket-step-input textarea,
body.dark .ticket-step-input input,
body.dark .row-input input,
body.dark .row-input textarea,
body.dark .row-input select,
body.dark .file-upload textarea {
  background-color: transparent;
}
body.dark .MuiNativeSelect-select:focus {
  background-color: transparent !important;
}
body.dark .control-room-tickets .MuiNativeSelect-select:focus {
  background-color: #fff !important;
}
body.dark
  .control-room-monitoring-header
  .screen-icons
  ion-segment-button.segment-button-checked {
  background-color: var(--ion-color-primary);
  color: white;
}
body.dark
  .control-room-monitoring-header
  .screen-icons
  ion-segment-button
  .screen-icon
  path {
  fill: #999ba1;
}
body.dark
  .control-room-monitoring-header
  .screen-icons
  ion-segment-button.segment-button-checked
  .screen-icon
  path {
  fill: white;
}

body.dark
  .control-room-monitoring
  .grafana-monitoring-panels
  .grafana-monitoring-panel {
  background-color: transparent;
  color: #fff;
}
body.dark .control-room-monitoring .grafana-monitoring-panel-select {
  background-color: transparent;
}
body.dark .ticket-data-table ion-col ion-row ion-col {
  background-color: transparent;
  border: 2px solid #a3a2a2;
  padding: 0.5rem 1rem;
}
body.dark .ticket-data-table .ticket-error-datetime,
body.dark .MuiButtonBase-root.small-accordion,
body.dark .ticket-data-table .ticket-error-satellite-summary {
  background-color: transparent;
  border: 2px solid #a3a2a2;
}
body.dark .ticket-data-table .transaction-details,
body.dark .MuiButtonBase-root.small-accordion .small-accordion-details,
body.dark .ticket-error-satellite .ticket-error-satellite-container,
body.dark .ticket-data-table .transaction-details ion-col,
body.dark .ticket-error-satellite ion-col,
body.dark .ticket-data-table ion-row.color-medium ion-col,
body.dark table.tickets-station-alerts-table,
body.dark .place-stations-accordion .MuiCollapse-container,
body.dark .multiscreen-monitoring-page {
  background-color: transparent;
  background-color: transparent;
  background-color: transparent;
}
body.dark .ticket-data-table .text-medium {
  color: val(--ion-color-medium);
}
body.dark .ticket-data-table ion-row.color-medium {
  color: val(--ion-color-medium);
}
body.dark
  table.tickets-station-alerts-table
  .MuiTableCell-root.MuiTableCell-body {
  color: var(--ion-color-medium) !important;
}
body.dark .place-stations-accordion .place-data-table ion-col {
  background-color: transparent;
  border: 2px solid #f5f6f9;
}
body.dark .station-panel-modal #ion-react-wrapper {
  background-color: #d2eee9 !important;
}
body.dark .alert-success {
  background-color: #d2eee9;
}
body.dark .errors-table {
  background-color: transparent;
}
body.dark .stepper-header .MuiMobileStepper-root {
  background-color: transparent !important;
}
body.dark .control-room-tickets {
  background-color: transparent;
}

body.dark .control-room-ticket-wrapper {
  background-color: transparent;
}
body.dark .control-room-ticket-modal {
  color: #fff;
  --box-shadow: 0 0px 4px rgba(255, 255, 255, 1);
}
body.dark .control-room-ticket-modal .button ion-icon {
  color: #fff !important;
}
body.dark .control-room-ticket-wrapper ion-badge {
  color: #fff;
}
